import {
  ButtonGroup,
  Card,
  InlineStack,
  BlockStack,
  Text,
  Button,
} from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import { useFeature } from '@/hooks/feature';
import { useSupport } from '@/hooks/support';

export const FlowbaseBanner = () => {
  const {
    value: isDismissed,
    update: updateDismissed,
    loading,
    isUpdating,
  } = useFeature('flowbaseDismissed');
  const { update: updateFlowbaseClicked } = useFeature('flowbaseClicked');

  const { startContactSupport } = useSupport(1);

  if (loading || isDismissed) {
    return null;
  }

  return (
    <div
      style={{
        padding: '0 var(--p-space-600)',
        marginTop: 'var(--p-space-600)',
      }}
    >
      <Card padding="0">
        <div
          style={{
            background: 'var(--p-color-bg-surface-info)',
            padding: 'var(--p-space-400)',
            position: 'relative',
          }}
        >
          <InlineStack gap="600">
            <BlockStack>
              <img
                alt=""
                width="100%"
                height="100%"
                style={{
                  maxHeight: '120px',
                  objectFit: 'contain',
                  objectPosition: 'center',
                  borderRadius: 'var(--p-border-radius-400)',
                }}
                src="/assets/flowbase-banner.png"
              />
            </BlockStack>
            <BlockStack gap="600">
              <BlockStack gap="200">
                <Text variant="headingMd">
                  A new FREE app from the Assortion team 🎉
                </Text>
                <Text>
                  Checkout the new Flowbase app, a powerful no-code editor for
                  creating signup forms, popups, and more.
                </Text>
              </BlockStack>
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={() => {
                    window.open(
                      'https://apps.shopify.com/flowbase?utm_source=assortion&utm_medium=app_banner',
                      '_blank'
                    );
                    updateFlowbaseClicked(true);
                  }}
                >
                  Try it out
                </Button>
                <Button variant="tertiary" onClick={startContactSupport}>
                  Learn more
                </Button>
              </ButtonGroup>
            </BlockStack>
          </InlineStack>
          <div
            style={{
              position: 'absolute',
              top: 'var(--p-space-600)',
              right: 'var(--p-space-600)',
            }}
          >
            <Button
              variant="plain"
              icon={CancelSmallMinor}
              accessibilityLabel="Dismiss card"
              loading={isUpdating}
              onClick={() => updateDismissed(true)}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
